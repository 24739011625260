import React from 'react';
import { useRouter } from 'next/router';

import * as Styled from './index.styles';

export default function NotFount() {
  const router = useRouter();

  return (
    <Styled.Container>
      <div>
        <Styled.Title>
          앗!
          <br />
          문제가 발생하여 페이지를
          <br />
          불러오지 못했어요ㅠㅠ
        </Styled.Title>
        <Styled.SubTitle>
          반복적으로 문제가 발생할 경우 문의를 남겨주시면
          <br />
          재빨리 처리해드릴게요!
          <div className="cs-link" onClick={() => window.open('http://pf.kakao.com/_vxnMbC/chat')}>
            카톡으로 문의하기
          </div>
        </Styled.SubTitle>
      </div>
      <Styled.Buttons>
        <Styled.Button onClick={() => router.back()}>이전 페이지</Styled.Button>
        <Styled.Button active onClick={() => router.replace('/')}>
          완료
        </Styled.Button>
      </Styled.Buttons>
    </Styled.Container>
  );
}
